import React from "react";
import { Link, graphql } from "gatsby";

import { connect } from "react-redux";
import Helmet from "react-helmet";
import { fadeIn, bounceInDown } from "react-animations";
import styled, { keyframes } from "styled-components";
import LazyLoad from "react-lazyload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COLORS, FONT_FAMILIES } from "../utils/theme";
const iconColor = "#29abe2";

import { StyledComponentsBreakpoints } from "../components/Layout/Responsive";

import Button from "../components/Forms/Button";

const getMetaTitle = () => {
  const pageTitle = "THE FEST 22 - October 25-27, 2024 - Gainesville, FL";
  return pageTitle;
};



class Fest22Teaser extends React.Component {
  render() {

    return (
      <>
        <Helmet title={getMetaTitle()} />
            <BackgroundPhoto>
            </BackgroundPhoto>
        <LazyLoad>
            {/* 
          <Phone>
          </Phone>
          <NotPhone>
            <VideoContainer>
              <VideoContainerIframe src="https://player.vimeo.com/video/907588941?h=749fa87544&autoplay=1&background=1&loop=1&muted=1&quality=720p"
                frameborder="0" allow="autoplay; fullscreen" allowfullscreen></VideoContainerIframe>
            </VideoContainer>
          </NotPhone>
            */}
          <ContentOverlayContainer>
            <h1 style={{fontSize: 96}}>FEST 22</h1>
            <h2 style={{ color: "#fff", fontSize: 32, marginTop: -16, paddingTop: 0, opacity: 0.666 }}>October 25-27, 2024</h2>

            <h6 style={{ marginTop: -0, paddingTop: 0, opacity: 0.420 }}>Gainesville, Florida</h6>
            <br /><br />
            <h5>Upcoming Dates</h5>
            <table>
              <tr><td style={{ textAlign: "right", paddingLeft: 12, paddingRight: 4 }}>April 15:</td>
                <td style={{ textAlign: "left" }}>FEST 22 Site Launch & First Lineup Announce</td></tr>
              <tr><td style={{ textAlign: "right", paddingLeft: 12, paddingRight: 4 }}>April 22:</td>
                <td style={{ textAlign: "left" }}>FEST 22 Passes & Hotels On Sale</td></tr>
            </table>

            <hr />
            <h6>
              WATCH THE FEST 21 HIGHLIGHTS
            </h6>
            <div class="video-container-wrapper" style={{textAlign: "center", margin: "0 auto"}}>
              <div class="video-container">
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/WiqOUx84ElQ?si=M8SkgRXSHJojGzCd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

              </div>
            </div>
            <br /><br />
            <h6>AND FOLLOW @THEFESTFL FOR MORE UPDATES</h6>
            <SocialButtonContainer>

              <Button
                variant="flat"
                onClick={() => {
                  window.open("https://instagram.com/thefestfl", "_blank");
                }}
              >
                <ExternalLink>
                  <FontAwesomeIcon
                    icon={["fab", "instagram"]}
                    color={iconColor}
                  />
                  {" "}
                </ExternalLink>
              </Button>

              <Button
                variant="flat"
                onClick={() => {
                  window.open("https://twitter.com/thefestfl", "_blank");
                }}
              >
                <ExternalLink>
                  <FontAwesomeIcon
                    icon={["fab", "twitter"]}
                    color={iconColor}
                  />
                  {" "}
                </ExternalLink>
              </Button>

              <Button
                variant="flat"
                onClick={() => {
                  window.open("https://facebook.com/thefestfl", "_blank");
                }}
              >
                <ExternalLink>
                  <FontAwesomeIcon
                    icon={["fab", "facebook"]}
                    color={iconColor}
                  />
                  {" "}
                </ExternalLink>
              </Button>

              <Button
                variant="flat"
                onClick={() => {
                  window.open("https://youtube.com/thefestfl", "_blank");
                }}
              >
                <ExternalLink>
                  <FontAwesomeIcon
                    icon={["fab", "youtube"]}
                    color={iconColor}
                  />
                  {" "}
                </ExternalLink>
              </Button>

              <Button
                variant="flat"
                onClick={() => {
                  window.open(
                    "https://flickr.com/photos/thefestfl/collections/",
                    "_blank"
                  );
                }}
              >
                <ExternalLink>
                  <FontAwesomeIcon
                    icon={["fab", "flickr"]}
                    color={iconColor}
                  />
                </ExternalLink>
              </Button>
            </SocialButtonContainer>
            <br />
            <em>WANNA SPONSOR OR VEND AT FEST 22?<br />
              E-MAIL RANDY: SPONSORTHEFEST@GMAIL.COM</em>
          </ContentOverlayContainer>
        </LazyLoad>
      </>
    );
  }
}

const fadeInAnimation = keyframes`${fadeIn}`;
const bounceInAnimation = keyframes`${bounceInDown}`;

const Phone = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    display:inherit;
  `};

  ${StyledComponentsBreakpoints.NotPhone`
    display:none;
  `};
`;
const NotPhone = styled.div`
  ${StyledComponentsBreakpoints.Phone`
    display:none;
  `};

  ${StyledComponentsBreakpoints.NotPhone`
    display:inherit;
  `};
`;

const BackgroundPhoto = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;

  background-image: url("https://static.thefestfl.com/fest21/53363407849_1f2ce57883_h.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const VideoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;

  @media (min-aspect-ratio: 16/9) {
    > iframe {
      /* height = 100 * (9 / 16) = 56.25 */
      height: 56.25vw;
    }
  }
      
  @media (max-aspect-ratio: 16/9) {
    > iframe {
      /* width = 100 / (9 / 16) = 177.777777 */
      width: 177.78vh;
    }
  }
`;

const VideoContainerIframe = styled.iframe`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
`;

const ContentOverlayContainer = styled.div`
  background: rgba(0, 0, 0, .9);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);

  width: 80%;
  max-width: 500px;
  padding: 36px 72px;
  text-align: center;


  ${StyledComponentsBreakpoints.Phone`
    position: relative;
    top: inherit;
    left: inherit;
    transform: inherit;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 36px 4px 44px;

    hr { 
      opacity: 0.1
    }
  `};
`;

const SocialButtonContainer = styled.div`
button {
  margin: 0 !important;
  padding: 4px 8px !important;
  min-width: 0 !important;

  &:hover {
    background: none;
  }
}`;

const ExternalLink = styled.div`
  svg {
    height: 24px !important;
    width: 24px !important;
    margin-right: -6px;
  }

  font-size: 1rem;
  text-decoration: none;
  line-height: 1.6;

  ${StyledComponentsBreakpoints.Phone`
    svg {
      height: 24px;
      width: 24px;
    }
  `};
`;

const connectedFestHome = connect()(Fest22Teaser);
export default connectedFestHome;
